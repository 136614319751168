import { login as authLogin, logout as authLogout } from '../components/services/authService';

// Token validation
export const isAuthenticated = () => {
  const token = localStorage.getItem('token');
  if (!token) return false;
  
  try {
    // Basic validation - check if token exists and isn't expired
    const payload = JSON.parse(atob(token.split('.')[1]));
    const expiry = payload.exp * 1000; // Convert to milliseconds
    return Date.now() < expiry;
  } catch (e) {
    return false;
  }
};

// API request helper with auth header
export const authenticatedFetch = async (url, options = {}) => {
  const token = localStorage.getItem('token');
  const headers = {
    ...options.headers,
    'Authorization': `Bearer ${token}`,
    'Content-Type': 'application/json',
  };

  try {
    const response = await fetch(url, {
      ...options,
      headers,
    });
    
    if (response.status === 401) {
      // Token expired or invalid
      authLogout();
      window.location.href = '/login';
      return null;
    }

    return response;
  } catch (error) {
    console.error('API request failed:', error);
    throw error;
  }
};

export { authLogin as login, authLogout as logout };