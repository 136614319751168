import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { logout } from './services/authService';

const AppNavbar = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate('/');  // Redirect to home page after logout
  };

  return (
    <Navbar bg="light" expand="lg">
      <Navbar.Brand as={Link} to="/">
        <img
          src="https://stackbd.com/wp-content/uploads/2022/10/cropped-logo-no-background.png"
          width="30"
          height="30"
          className="d-inline-block align-top"
          alt="Stack BD logo"
        />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          <Nav.Link as={Link} to="/gdpr">GDPR</Nav.Link>
          <Nav.Link as={Link} to="/ccpa">CCPA</Nav.Link>
          <Nav.Link as={Link} to="/hipaa">HIPAA</Nav.Link>
          <Nav.Link as={Link} to="/fcra">FCRA</Nav.Link>
        </Nav>
        <Nav>
        <NavDropdown title="Profile" id="basic-nav-dropdown" alignRight>
          <NavDropdown.Item as={Link} to="/account">My Account</NavDropdown.Item>
          <NavDropdown.Divider />
          <NavDropdown.Item onClick={handleLogout}>Sign Out</NavDropdown.Item>
        </NavDropdown>
      </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default AppNavbar;