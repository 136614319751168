import React from 'react';
import { Link, useParams, useLocation } from 'react-router-dom';
import { downloadReport } from '../api';
import '../global.css';

function DashboardPage() {
  const { complianceType, crmType } = useParams();
  const location = useLocation();
  const analysisResult = location.state?.analysisResult;

  if (!analysisResult) {
    return <div>No analysis results available. Please perform an analysis first.</div>;
  }

  const handleDownload = async () => {
    try {
      const blob = await downloadReport(complianceType, crmType);
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${complianceType}_${crmType}_report.csv`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error('Error downloading report:', error);
      alert('Failed to download report. Please try again later.');
    }
  };

  // Extract top 3 critical issues from non_compliant_records
  const topCriticalIssues = analysisResult.non_compliant_records
    ? analysisResult.non_compliant_records
        .sort((a, b) => {
          const severityOrder = { high: 0, medium: 1, low: 2 };
          return severityOrder[a.severity] - severityOrder[b.severity];
        })
        .slice(0, 3)
        .map(record => record.issue)
    : [];

  return (
    <div className="page-container">
      <div className="dashboard-square" style={{ backgroundColor: 'var(--color-yellow)' }}>
        <h2>Overall Score</h2>
        <p className="score">{analysisResult.overall_score?.toFixed(2)}%</p>
        <h3>Top 3 Critical Issues:</h3>
        <ul>
          {topCriticalIssues.map((issue, index) => (
            <li key={index}>{issue}</li>
          ))}
        </ul>
      </div>
      <Link to={`/executive-summary/${complianceType}/${crmType}`} className="dashboard-square" style={{ backgroundColor: 'var(--color-purple)' }}>
        <h2>Executive Summary</h2>
      </Link>
      <Link to={`/full-report/${complianceType}/${crmType}`} className="dashboard-square" style={{ backgroundColor: 'var(--color-teal)' }}>
        <h2>View Full Report</h2>
      </Link>
      <button onClick={handleDownload} className="dashboard-square" style={{ backgroundColor: 'var(--color-pink)', border: 'none', cursor: 'pointer' }}>
        <h2>Download Full Report</h2>
        <svg className="download-icon" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
          <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
          <polyline points="7 10 12 15 17 10"></polyline>
          <line x1="12" y1="15" x2="12" y2="3"></line>
        </svg>
      </button>
    </div>
  );
}

export default DashboardPage;