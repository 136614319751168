import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import ProtectedRoute from './components/ProtectedRoute';
import LandingPage from './components/LandingPage';
import LoginPage from './components/LoginPage';
import SignUpPage from './components/SignUpPage';
import ComplianceTypePage from './components/ComplianceTypePage';
import CRMSelectionPage from './components/CRMSelectionPage';
import AnalysisPage from './components/AnalysisPage';
import DashboardPage from './components/DashboardPage';
import ExecutiveSummaryPage from './components/ExecutiveSummaryPage';
import FullReportPage from './components/FullReportPage';
import HubSpotConnector from './components/HubSpotConnector';
import TransitionComponent from './components/TransitionComponent';
import { useTransition } from './useTransition';
import AppNavbar from './components/Navbar';
import 'bootstrap/dist/css/bootstrap.min.css';
import './global.css';

function AppContent() {
  const { isTransitioning, transitionDuration } = useTransition();
  const location = useLocation();

  return (
    <div className="App">
      <AppNavbar />
      {isTransitioning && <TransitionComponent duration={transitionDuration} />}
      <Routes location={location}>
        <Route path="/" element={<LandingPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/signup" element={<SignUpPage />} />
        <Route 
          path="/compliance/:type" 
          element={<ProtectedRoute component={ComplianceTypePage} />} 
        />
        <Route 
          path="/crm-selection/:complianceType/:crmType" 
          element={<ProtectedRoute component={CRMSelectionPage} />} 
        />
        <Route 
          path="/analysis/:complianceType/:crmType" 
          element={<ProtectedRoute component={AnalysisPage} />} 
        />
        <Route 
          path="/dashboard/:complianceType/:crmType" 
          element={<ProtectedRoute component={DashboardPage} />} 
        />
        <Route 
          path="/executive-summary/:complianceType/:crmType" 
          element={<ProtectedRoute component={ExecutiveSummaryPage} />} 
        />
        <Route 
          path="/full-report/:complianceType/:crmType" 
          element={<ProtectedRoute component={FullReportPage} />} 
        />
        <Route 
          path="/hubspot-connect" 
          element={<ProtectedRoute component={HubSpotConnector} />} 
        />
      </Routes>
    </div>
  );
}

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;