import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || 'https://app.stackbd.com/api/v1';

const api = axios.create({
  baseURL: API_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const checkCompliance = async (data) => {
  try {
    console.log('Sending compliance check data:', JSON.stringify(data, null, 2));
    const response = await api.post('/checks/', data);
    console.log('Compliance check response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error in compliance check:', error.response?.data);
    throw error;
  }
};

export const analyzeHubSpotData = async (complianceType, crmType = 'HubSpot') => {
  try {
    console.log('Analyzing HubSpot data:', { complianceType, crmType });
    const response = await api.post('/hubspot/perform-analysis', { complianceType, crmType });
    console.log('HubSpot analysis response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error analyzing HubSpot data:', error.response?.data);
    throw error;
  }
};

export const getExecutiveSummary = async (complianceType, crmType) => {
  try {
    console.log('Fetching executive summary:', { complianceType, crmType });
    const response = await api.get(`/hubspot/executive-summary/${complianceType}/${crmType}`);
    console.log('Executive summary response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching executive summary:', error.response?.data);
    throw error;
  }
};

export const getFullReport = async (complianceType, crmType) => {
  try {
    console.log('Fetching full report:', { complianceType, crmType });
    const response = await api.get(`/hubspot/full-report/${complianceType}/${crmType}`);
    console.log('Full report response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching full report:', error.response?.data);
    throw error;
  }
};

export const downloadReport = async (complianceType, crmType) => {
  try {
    console.log('Downloading report:', { complianceType, crmType });
    const response = await api.get(`/hubspot/download-report/${complianceType}/${crmType}`, {
      responseType: 'blob'
    });
    console.log('Download report response received');
    return response.data;
  } catch (error) {
    console.error('Error downloading report:', error.response?.data);
    throw error;
  }
};

export default api;