import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './HubSpotConnector.css';

function HubSpotConnector() {
  const [connected, setConnected] = useState(false);
  const navigate = useNavigate();

  const handleConnect = () => {
    // Simulating HubSpot connection process
    setTimeout(() => {
      setConnected(true);
      setTimeout(() => {
        navigate('/');
      }, 2000);
    }, 2000);
  };

  return (
    <div className="hubspot-connector">
      <h2>Connect to HubSpot</h2>
      {!connected ? (
        <button onClick={handleConnect}>Connect HubSpot Account</button>
      ) : (
        <div>
          <p>Successfully connected to HubSpot!</p>
          <p>Redirecting to compliance check...</p>
        </div>
      )}
    </div>
  );
}

export default HubSpotConnector;