import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { analyzeHubSpotData } from '../api';
import '../global.css';

function AnalysisPage() {
  const [isAnalyzing, setIsAnalyzing] = useState(false);
  const [error, setError] = useState(null);
  const { complianceType, crmType } = useParams();
  const navigate = useNavigate();

  const performAnalysis = async () => {
    setIsAnalyzing(true);
    setError(null);
    try {
      console.log('Starting analysis with:', { complianceType, crmType });
      const result = await analyzeHubSpotData(complianceType, crmType);
      console.log('Analysis result:', result);
      navigate(`/dashboard/${complianceType}/${crmType}`, { state: { analysisResult: result } });
    } catch (error) {
      console.error('Error performing analysis:', error);
      setError(`An error occurred while performing the analysis: ${error.message}`);
    } finally {
      setIsAnalyzing(false);
    }
  };

  return (
    <div className="analysis-page">
      {error && <p className="error-message">{error}</p>}
      <button 
        className="analysis-button" 
        onClick={performAnalysis}
        disabled={isAnalyzing}
      >
        {isAnalyzing ? 'Analyzing...' : 'Perform HubSpot Analysis'}
      </button>
    </div>
  );
}

export default AnalysisPage;