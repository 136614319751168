// src/components/TransitionComponent.js
import React, { useState, useEffect } from 'react';

function TransitionComponent({ duration }) {
  const [shape, setShape] = useState('triangle');

  useEffect(() => {
    const shapes = ['triangle', 'hexagon', 'octagon'];
    let currentIndex = 0;

    const intervalId = setInterval(() => {
      currentIndex = (currentIndex + 1) % shapes.length;
      setShape(shapes[currentIndex]);
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="transition-container" style={{ animation: `fadeOut ${duration}ms forwards` }}>
      <div className={`transition-shape ${shape}`}></div>
    </div>
  );
}

export default TransitionComponent;