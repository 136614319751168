// src/useTransition.js
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export function useTransition() {
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [transitionDuration, setTransitionDuration] = useState(3000);
  const location = useLocation();

  useEffect(() => {
    setIsTransitioning(true);
    
    // Set duration based on the current route
    if (location.pathname.includes('/dashboard') || 
        location.pathname.includes('/executive-summary') || 
        location.pathname.includes('/full-report')) {
      setTransitionDuration(10000);
    } else {
      setTransitionDuration(3000);
    }

    const timer = setTimeout(() => {
      setIsTransitioning(false);
    }, transitionDuration);

    return () => clearTimeout(timer);
  }, [location, transitionDuration]);

  return { isTransitioning, transitionDuration };
}