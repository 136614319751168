import React from 'react';
import { Link, useParams } from 'react-router-dom';
import '../global.css';

const CRMSquare = ({ complianceType, crmType, color, title, isComingSoon }) => {
  if (isComingSoon) {
    return (
      <div className="square" style={{ backgroundColor: color }} onClick={() => alert("Coming Soon. Sign Up for Early Access.")}>
        <h2>{title}</h2>
        <p>Coming Soon</p>
      </div>
    );
  }
  return (
    <Link to={`/crm-selection/${complianceType}/${crmType}`} className="square" style={{ backgroundColor: color }}>
      <h2>{title}</h2>
    </Link>
  );
};

function ComplianceTypePage() {
  const { type } = useParams();

  return (
    <div className="page-container">
      <h1>{type.toUpperCase()} Compliance Check</h1>
      <div className="page-container">
        <CRMSquare complianceType={type} crmType="salesforce" color="var(--color-yellow)" title="Salesforce" isComingSoon={true} />
        <CRMSquare complianceType={type} crmType="hubspot" color="var(--color-purple)" title="HubSpot" isComingSoon={false} />
        <CRMSquare complianceType={type} crmType="dynamics365" color="var(--color-teal)" title="Microsoft Dynamics 365" isComingSoon={true} />
        <CRMSquare complianceType={type} crmType="adobe" color="var(--color-pink)" title="Adobe" isComingSoon={true} />
      </div>
    </div>
  );
}

export default ComplianceTypePage;