import React from 'react';
import { Link } from 'react-router-dom';
import '../global.css';

const ComplianceSquare = ({ type, color, title }) => (
  <Link to={`/compliance/${type}`} className="square" style={{ backgroundColor: color }}>
    <h2>{title}</h2>
  </Link>
);

function LandingPage() {
  return (
    <div className="page-container">
      <ComplianceSquare type="gdpr" color="var(--color-yellow)" title="GDPR" />
      <ComplianceSquare type="ccpa" color="var(--color-purple)" title="CCPA" />
      <ComplianceSquare type="hipaa" color="var(--color-teal)" title="HIPAA" />
      <ComplianceSquare type="fcra" color="var(--color-pink)" title="FCRA" />
    </div>
  );
}

export default LandingPage;