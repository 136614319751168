import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getFullReport } from '../api';
import './FullReportPage.css';

function FullReportPage() {
  const { complianceType, crmType } = useParams();
  const [report, setReport] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    getFullReport(complianceType, crmType)
      .then(data => {
        setReport(data);
      })
      .catch(err => {
        console.error('Error fetching full report:', err);
        setError('Failed to load full report. Please try again later.');
      });
  }, [complianceType, crmType]);

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  if (!report) {
    return <div>Loading...</div>;
  }

  return (
    <div className="full-report-page">
      <h1>Full Report: {complianceType.toUpperCase()} Compliance for {crmType.toUpperCase()}</h1>
      <div className="report-content">
        <h2>Overall Compliance Score: {report.overall_score?.toFixed(2)}%</h2>
        {Object.entries(report.component_scores || {}).map(([component, score]) => (
          <div key={component} className="report-section">
            <h3>{component.replace(/_/g, ' ').toUpperCase()}</h3>
            <p>Score: {score.toFixed(2)}%</p>
          </div>
        ))}
        <h2>Detailed Findings:</h2>
        <table>
          <thead>
            <tr>
              <th>Category</th>
              <th>Issue</th>
              <th>Remediation</th>
              <th>Severity</th>
            </tr>
          </thead>
          <tbody>
            {report.non_compliant_records?.map((record, index) => (
              <tr key={index}>
                <td>{record.category}</td>
                <td>{record.issue}</td>
                <td>{record.remediation}</td>
                <td className={record.severity}>{record.severity}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default FullReportPage;