import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import '../global.css';

const CRMSquare = ({ name, color, onClick }) => (
  <div className="square" style={{ backgroundColor: color }} onClick={onClick}>
    <h2>{name}</h2>
  </div>
);

const ComingSoonModal = ({ onClose }) => (
  <>
    <div className="modal-backdrop" onClick={onClose}></div>
    <div className="modal">
      <button className="close-button" onClick={onClose}>×</button>
      <h2>Coming Soon</h2>
      <p>Sign Up for Early Access</p>
      <input type="text" placeholder="First Name" />
      <input type="text" placeholder="Last Name" />
      <input type="email" placeholder="Email" />
      <button>Submit</button>
    </div>
  </>
);

function CRMSelectionPage() {
  const [showModal, setShowModal] = useState(false);
  const { complianceType, crmType } = useParams();
  const navigate = useNavigate();

  const handleCRMClick = (clickedCrmType) => {
    if (clickedCrmType === 'hubspot') {
      navigate(`/analysis/${complianceType}/${clickedCrmType}`);
    } else {
      setShowModal(true);
    }
  };

  return (
    <div className="page-container">
      <CRMSquare name="Salesforce" color="var(--color-yellow)" onClick={() => handleCRMClick('salesforce')} />
      <CRMSquare name="HubSpot" color="var(--color-purple)" onClick={() => handleCRMClick('hubspot')} />
      <CRMSquare name="Microsoft Dynamics 365" color="var(--color-teal)" onClick={() => handleCRMClick('dynamics365')} />
      <CRMSquare name="Adobe" color="var(--color-pink)" onClick={() => handleCRMClick('adobe')} />
      {showModal && <ComingSoonModal onClose={() => setShowModal(false)} />}
    </div>
  );
}

export default CRMSelectionPage;