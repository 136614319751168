import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getExecutiveSummary } from '../api';
import './ExecutiveSummaryPage.css';

function ExecutiveSummaryPage() {
  const { complianceType, crmType } = useParams();
  const [summary, setSummary] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    getExecutiveSummary(complianceType, crmType)
      .then(data => {
        setSummary(data);
      })
      .catch(err => {
        console.error('Error fetching executive summary:', err);
        setError('Failed to load executive summary. Please try again later.');
      });
  }, [complianceType, crmType]);

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  if (!summary) {
    return <div>Loading...</div>;
  }

  return (
    <div className="executive-summary-page">
      <h1>Executive Summary: {complianceType.toUpperCase()} Compliance for {crmType.toUpperCase()}</h1>
      <div className="summary-content">
        <h2>Overall Compliance Score: {summary.overall_score?.toFixed(2)}%</h2>
        <h3>Key Metrics:</h3>
        <ul>
          <li>Total Records Analyzed: {summary.total_records}</li>
          <li>Non-Compliant Fields: {summary.total_non_compliant_fields}</li>
        </ul>
        <h3>Critical Issues:</h3>
        <ul>
          {summary.critical_issues?.map((issue, index) => (
            <li key={index}>{issue}</li>
          ))}
        </ul>
        <h3>Top Recommendations:</h3>
        <ul>
          {summary.top_recommendations?.map((recommendation, index) => (
            <li key={index}>{recommendation}</li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default ExecutiveSummaryPage;